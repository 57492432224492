import { TopInlineMark } from 'components/topMark/TopMarks';
import { localize } from 'i18n/localize';

export const TopMarkExplanation = () => {
	const { footer } = localize();

	return (
		<p className="font-light text-xs mt-5 mb-4">
			{footer.beforeTopSentence} <TopInlineMark /> {footer.afterTopSentence}{' '}
			<a className="hover:underline text-neutral-100 hover:text-red" href={footer.moreInformationURL}>
				{footer.moreInformation}
			</a>
		</p>
	);
};
