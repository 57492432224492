import { localize } from 'i18n/localize.ts';

const $t = localize();

const CLASSNAME = 'c-top-mark';

export function TopCornerMark() {
	const CLASSNAME_CORNER = `${CLASSNAME}--corner`;
	return (
		<span className={`${CLASSNAME} ${CLASSNAME_CORNER}`}>
			<span className={`${CLASSNAME_CORNER}__content`} data-content={$t.topMark.top}>
				{$t.topMark.topped}
			</span>
		</span>
	);
}

export function TopInlineMark() {
	return (
		<span class="bg-red-100 text-neutral-white font-bold rounded-sm px-1.5 py-[2px]" data-content={$t.topMark.top}>
			{$t.topMark.top}
		</span>
	);
}
