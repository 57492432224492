import { localize } from 'i18n/localize';
import LogoSbazar from 'components/logoSbazar/LogoSbazar';
import { loadScript } from 'utils/loadScript';
import './SBazarPart.less';
import { TopMarkExplanation } from './TopMarkExplanation';

const LI_CLASSES = 'mr-4 lg:mr-0 lg:basis-[33%]';
const A_CLASSES = 'text-sm font-medium leading-[2.8]';

const CMP_SCRIPT = 'https://h.seznam.cz/js/cmp2/scmp.js';
const { footer, termsOfConditionsURL } = localize();

export const SbazarPart = () => {
	return (
		<div class="lg:flex pb-6 text-left text-neutral-100">
			<div class="basis-[13.25rem] flex-shrink-0 [&_svg]:w-[8rem] md:[&_svg]:w-[10.5rem] lg:pt-4 border-b border-solid border-neutral-15 lg:border-0">
				<LogoSbazar className="my-4 fill" />
			</div>
			<div>
				<ul class="sm:flex-wrap sm:inline-flex mt-5 lg:max-w-[36rem] lg:flex lg:mt-4 flex-grow">
					<li class={LI_CLASSES}>
						<a
							className={A_CLASSES}
							href="https://surveys.hotjar.com/6f44bb41-0184-4ee8-8f5c-8ec8ccf1ad8c"
							target="_blank"
						>
							{footer.feedback}
						</a>
					</li>
					<li class={LI_CLASSES}>
						<a class={A_CLASSES} href={footer.complaintURL} rel="noreferrer" target="_blank">
							{footer.complaint}
						</a>
					</li>
					<li class={LI_CLASSES}>
						<a className={A_CLASSES} href={footer.developerURL} rel="noreferrer" target="_blank">
							{footer.developer}
						</a>
					</li>
					<li class={LI_CLASSES}>
						<a class={A_CLASSES} href={footer.helpURL} rel="noreferrer" target="_blank">
							{footer.help}
						</a>
					</li>
					<li class={LI_CLASSES}>
						<a class={A_CLASSES} href={termsOfConditionsURL} rel="noreferrer" target="_blank">
							{footer.forCustomers}
						</a>
					</li>
					<li class={LI_CLASSES}>
						<button
							class={A_CLASSES}
							onClick={async (event) => {
								event.preventDefault();
								event.stopPropagation();

								const { scmp } = window;
								// It may not be loaded, because sssp.js script loads scmp via getAds function call
								if (!scmp) {
									try {
										await loadScript(CMP_SCRIPT);
										const onReadyScmp = () => {
											globalThis.window.scmp?.open();
											window.removeEventListener('scmp_ready', onReadyScmp);
										};

										window.addEventListener('scmp_ready', onReadyScmp);
									} catch (error) {
										console.error('Error loading CMP script', error);
									}
								} else {
									scmp.open();
								}
							}}
						>
							{footer.consentManagementPlatform}
						</button>
					</li>
				</ul>
				<TopMarkExplanation />
			</div>
		</div>
	);
};
